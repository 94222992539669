import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import InvoiceDataGeneratorPage from './pages/InvoiceDataGeneratorPage';
import InvoicePage from './pages/InvoicePage';
import InvoicePdfPage from './pages/InvoicePdfPage';
import AgentPage from './pages/AgentPage';
import StorePage from './pages/StorePage';
import AgentInvoicePage from './pages/AgentInvoicePage';



function App() {
  return (
    <Router>
      <AuthProvider>
        <Application />
      </AuthProvider>
    </Router>
  );
}

function Application() {
  return (
    <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/home" element={<PrivateRoute><Home/></PrivateRoute>} />
          <Route path="/invoice-data" element={<PrivateRoute><InvoiceDataGeneratorPage/></PrivateRoute>} />
          <Route path="/invoices" element={<PrivateRoute><InvoicePage/></PrivateRoute>} />
          <Route path="/invoices/:invoiceId" element={<PrivateRoute><InvoicePdfPage/></PrivateRoute>} />
          <Route path="/agents" element={<PrivateRoute><AgentPage/></PrivateRoute>} />
          <Route path="/stores" element={<PrivateRoute><StorePage/></PrivateRoute>} />
          <Route path="/agent-invoices" element={<PrivateRoute><AgentInvoicePage/></PrivateRoute>} />
        </Routes>
    </div>
  );
}

export default App;