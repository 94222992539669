import { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_ENDPOINTS } from '../settings.js';
import LoginPage from '../pages/LoginPage';

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null);
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
    }, [loading]);
    useEffect(() => {
        if (authTokens) {
            setUser(jwt_decode(authTokens.access));
        } else {
            setUser(null);
        }
    }, [authTokens]);

    const logoutUser = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        navigate('/login', { state: { from: location } });
    }, [navigate, location]);

    const updateToken = useCallback(async () => {
        const tokenShouldBeRefreshed = authTokens && new Date().getTime() / 1000 > jwt_decode(authTokens.access).exp - (10 * 60); // 50 minutes before expiry
        if (tokenShouldBeRefreshed) {
            let response = await fetch(API_ENDPOINTS.TOKEN_REFRESH, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'refresh': authTokens?.refresh })
            });

            let data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwt_decode(data.access));
                localStorage.setItem('authTokens', JSON.stringify(data));
            } else {
                logoutUser();
            }
        }

        if (loading) {
            setLoading(false);
        }
    }, [authTokens, loading, logoutUser]);

    // Inactivity Logout Logic
    useEffect(() => {
        const handleInactivity = () => {
            logoutUser();
        };

        let inactivityTimeout = setTimeout(handleInactivity, 3600000); // 1 hour

        const resetInactivityTimeout = () => {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(handleInactivity, 3600000); // Reset timer on any activity
        };

        document.addEventListener('click', resetInactivityTimeout);

        return () => {
            clearTimeout(inactivityTimeout);
            document.removeEventListener('click', resetInactivityTimeout);
        };
    }, [logoutUser]);

    // Refresh token on activity
    useEffect(() => {
        const handleActivity = () => {
            updateToken();
        };

        document.addEventListener('click', handleActivity);

        return () => {
            document.removeEventListener('click', handleActivity);
        };
    }, [updateToken]);

    let contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: async (e) => {
            e.preventDefault();
            let response = await fetch(API_ENDPOINTS.TOKEN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'username': e.target.username.value, 'password': e.target.password.value })
            });
            let data = await response.json();

            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwt_decode(data.access));
                localStorage.setItem('authTokens', JSON.stringify(data));
                navigate('/home', { state: { from: location } });
            } else {
                alert('Something went wrong!');
            }
        },
        logoutUser: logoutUser,
    };

    return (
        <AuthContext.Provider value={contextData}>
            {authTokens ? (loading ? null : children) : <LoginPage />}
        </AuthContext.Provider>
    );
};