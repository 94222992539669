import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { API_ENDPOINTS } from '../settings.js';
function Dashboard() {
    let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    let [dashboardData, setDashboardData] = useState({});
    useEffect(() => {
        let fetchDashboardData = async () => {
            let response = await fetch(API_ENDPOINTS.DASHBOARD, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`
                }
            });
            let data = await response.json();
            setDashboardData(data);
        };
        fetchDashboardData();
    }, []);
    return (
        <>
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                    <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">format_list_numbered</i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">Total Orders</p>
                        <h4 className="mb-0">{dashboardData.total_orders}</h4>
                    </div>
                    </div>
                    <hr className="dark horizontal my-0" />
                    <div className="card-footer p-3">
                    <p className="mb-0"><span className="text-success text-sm font-weight-bolder"></span></p>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                    <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">monetization_on</i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">Total Store Revenue</p>
                        <h4 className="mb-0">${dashboardData.total_amount_invoice}</h4>
                    </div>
                    </div>
                    <hr className="dark horizontal my-0" />
                    <div className="card-footer p-3">
                    <p className="mb-0"><span className="text-success text-sm font-weight-bolder"></span></p>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                    <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">payments</i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">Total Agent Revenue</p>
                        <h4 className="mb-0">${dashboardData.total_amount_agent}</h4>
                    </div>
                    </div>
                    <hr className="dark horizontal my-0" />
                    <div className="card-footer p-3">
                    <p className="mb-0"><span className="text-danger text-sm font-weight-bolder"></span></p>
                    </div>
                </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                <div className="card">
                    <div className="card-header p-3 pt-2">
                    <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">account_balance_wallet</i>
                    </div>
                    <div className="text-end pt-1">
                        <p className="text-sm mb-0 text-capitalize">Total Invoice Amount</p>
                        <h4 className="mb-0">${dashboardData.total_amount_invoice}</h4>
                    </div>
                    </div>
                    <hr className="dark horizontal my-0" />
                    <div className="card-footer p-3">
                    <p className="mb-0"><span className="text-success text-sm font-weight-bolder"></span></p>
                    </div>
                </div>
                </div>
            </div>
            <div className="row mt-4">
                {/* <div className="col-lg-4 col-md-6 mt-4 mb-4">
                <div className="card z-index-2 ">
                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                        <div className="chart">
                        <canvas id="chart-bars" className="chart-canvas" height="170"></canvas>
                        </div>
                    </div>
                    </div>
                    <div className="card-body">
                    <h6 className="mb-0 ">Website Views</h6>
                    <p className="text-sm ">Last Campaign Performance</p>
                    <hr className="dark horizontal" />
                    <div className="d-flex ">
                        <i className="material-icons text-sm my-auto me-1">schedule</i>
                        <p className="mb-0 text-sm"> campaign sent 2 days ago </p>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-4 mb-4">
                <div className="card z-index-2  ">
                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                        <div className="chart">
                        <canvas id="chart-line" className="chart-canvas" height="170"></canvas>
                        </div>
                    </div>
                    </div>
                    <div className="card-body">
                    <h6 className="mb-0 "> Daily Sales </h6>
                    <p className="text-sm "> (<span className="font-weight-bolder">+15%</span>) increase in today sales. </p>
                    <hr className="dark horizontal" />
                    <div className="d-flex ">
                        <i className="material-icons text-sm my-auto me-1">schedule</i>
                        <p className="mb-0 text-sm"> updated 4 min ago </p>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-4 mt-4 mb-3">
                <div className="card z-index-2 ">
                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                        <div className="chart">
                        <canvas id="chart-line-tasks" className="chart-canvas" height="170"></canvas>
                        </div>
                    </div>
                    </div>
                    <div className="card-body">
                    <h6 className="mb-0 ">Completed Tasks</h6>
                    <p className="text-sm ">Last Campaign Performance</p>
                    <hr className="dark horizontal" />
                    <div className="d-flex ">
                        <i className="material-icons text-sm my-auto me-1">schedule</i>
                        <p className="mb-0 text-sm">just updated</p>
                    </div>
                    </div>
                </div>
                </div> */}
            </div>
            <div className="row mb-0 mt-5 " >
                <div className="col-lg-7 col-md-6 mb-md-0 mb-4">
                    <div className="card">
                        <div className="card-header pb-0">
                        <div className="row">
                            <div className="col-lg-6 col-7">
                            <h6>All Stores</h6>
                            <p className="text-sm mb-0">
                                <span className="font-weight-bold ms-1">Store Details</span> 
                            </p>
                            </div>
                            <div className="col-lg-6 col-5 my-auto text-end">
                            </div>
                        </div>
                        </div>
                        <div className="card-body px-0 pb-2">
                        <div className="table-responsive " style={{ minHeight: '56vh', overflowY: 'auto' }}>
                            <table className="table align-items-center mb-0" >
                            <thead>
                                <tr>
                                <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Name</th>
                                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-7">Total Orders</th>
                                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-7">Total Revenue</th>
                                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-7">Paid Invoices</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardData.store_data && Object.entries(dashboardData.store_data).map(([storeName, storeInfo], index) => {
                                    const pendingPercentage = storeInfo.invoices_pending + storeInfo.invoices_paid > 0 
                                        ? (storeInfo.invoices_paid / (storeInfo.invoices_pending + storeInfo.invoices_paid)) * 100 
                                        : 0;
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex px-2 py-1">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="mb-0 text-sm">{storeName}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <span className="text-xs font-weight-bold">{storeInfo.orders_count}</span>
                                            </td>
                                            <td className="text-center">
                                                <span className="text-xs font-weight-bold">${storeInfo.total_amount.toFixed(2)}</span>
                                            </td>
                                            <td className="align-middle">
                                                <div className="progress-wrapper w-75 mx-auto">
                                                    <div className="progress-info">
                                                        <div className="progress-percentage">
                                                            <span className="text-xs font-weight-bold">{pendingPercentage.toFixed(2)}%</span>
                                                        </div>
                                                    </div>
                                                    <div className="progress">
                                                        <div className="progress-bar bg-gradient-info" role="progressbar" style={{ width: `${pendingPercentage}%` }} aria-valuenow={pendingPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 mb-md-0 mb-4">
                    <div className="card">
                        <div className="card-header pb-0">
                        <div className="row">
                            <div className="col-lg-6 col-7">
                            <h6>All Agents</h6>
                            <p className="text-sm mb-0">
                                <span className="font-weight-bold ms-1">Agent Details</span> 
                            </p>
                            </div>
                            <div className="col-lg-6 col-5 my-auto text-end">
                            </div>
                        </div>
                        </div>
                        <div className="card-body px-0 pb-2">
                        <div className="table-responsive " style={{ minHeight: '56vh', overflowY: 'auto' }}>
                            <table className="table align-items-center mb-0">
                            <thead>
                                <tr>
                                <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Name</th>
                                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-7">Total Orders</th>
                                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-7">Total Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardData.agent_data && Object.entries(dashboardData.agent_data).map(([agentName, agentInfo], index) => (
                                    <tr key={index}>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-sm">{agentName}</h6>
                                        </div>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-xs font-weight-bold">{agentInfo.count}</span>
                                    </td>
                                    <td className="align-middle text-center text-sm">
                                        <span className="text-xs font-weight-bold">${agentInfo.amount.toFixed(2)}</span>
                                    </td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
    }

export default Dashboard

