import { useContext, useState } from 'react';
import './Dashboard.css';
import AuthContext from '../context/AuthContext';

const LogIn = () => {
  let { loginUser } = useContext(AuthContext);
  const [focused, setFocused] = useState(null);
  const [inputs, setInputs] = useState({ username: '', password: '', rememberMe: true });

  const handleFocus = (field) => {
    setFocused(field);
  };

  const handleBlur = () => {
    setFocused(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <>
      <main className="main-content mt-0">
        <div className="page-header align-items-start min-vh-100">
          <span className="mask opacity-6" style={{ background: 'linear-gradient(#f5f5f5, #80cbc4)' }}></span>
          <div className="container my-auto">
            <div className="row">
              <div className="col-lg-4 col-md-8 col-12 mx-auto">
                <div className="card z-index-0 fadeIn3 fadeInBottom">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                      <h2 className="text-white font-weight-bolder text-center mt-2 mb-0">Billing System</h2>
                      <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">Log in</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="text-start" onSubmit={loginUser}>
                      <div className={`input-group input-group-outline my-3 ${focused === 'username' || inputs.username ? 'is-filled' : ''}`}>
                        <label className="form-label">Username</label>
                        <input type="text" className="form-control" name="username" onFocus={() => handleFocus('username')} onBlur={handleBlur} onChange={handleChange} value={inputs.username}/>
                      </div>
                      <div className={`input-group input-group-outline mb-3 ${focused === 'password' || inputs.password ? 'is-filled' : ''}`}>
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" name="password" onFocus={() => handleFocus('password')} onBlur={handleBlur} onChange={handleChange} value={inputs.password}/>
                      </div>
                      <div className="form-check form-switch d-flex align-items-center mb-3">
                        <input className="form-check-input" type="checkbox" id="rememberMe" name="rememberMe" checked={inputs.rememberMe} onChange={handleChange} />
                        <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe">Remember me</label>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn bg-gradient-primary w-100 my-4 mb-2">Sign in</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LogIn;