import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS } from '../settings.js';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';

const Agents = () => {
    const [agents, setAgents] = useState([]);
    let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    const [nameFilter, setNameFilter] = useState(''); // Filter by agent name
    const [usernameFilter, setUsernameFilter] = useState(''); // Added filter by username
    const [emailFilter, setEmailFilter] = useState('');
    const [expandedAgentId, setExpandedAgentId] = useState(null); // Changed from expandedStoreId
    const [editMode, setEditMode] = useState(null);
    const [editableAgent, setEditableAgent] = useState({});
    const handleEdit = (agent) => {
      setEditMode(agent.agent_id);
      setEditableAgent({
          agent_id: agent.agent_id,
          username: agent.username,
          agent_name: agent.agent_name,
          agent_email: agent.agent_email,
          agent_phone: agent.agent_phone,
          agent_address: agent.agent_address,
          joining_date: agent.joining_date,
          pay_rate: agent.pay_rate,
          morning_shift_bonus: agent.morning_shift_bonus,
          cash_collected_from_customer: agent.cash_collected_from_customer,
          agent_type: agent.agent_type
      });
    };
    
    const handleChange = (field, value) => {
        setEditableAgent(prev => ({ ...prev, [field]: value }));
    };
    
    const handleSave = async () => {
        // Use editableAgent to update the agent details on the server
        try {
          const response = await fetch(`${API_ENDPOINTS.AGENT}${editMode}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authTokens.access}`
            },
            body: JSON.stringify(editableAgent)
          });
      
          if (response.ok) {
            notifySuccess('Agent updated successfully.');
            AgentData();
          } else {
            throw new Error('Failed to update agent');
          }
        } catch (error) {
          notifyError(error.message);
        }
        setEditMode(null); // Exit edit mode
    };
    
    const handleCancel = () => {
        setEditMode(null);
        setEditableAgent({}); // Reset the editable agent
    };
    const filteredAgents = agents.filter(agent => 
      agent.agent_name.toLowerCase().includes(nameFilter.toLowerCase()) &&
      agent.username.toLowerCase().includes(usernameFilter.toLowerCase()) &&
      agent.agent_email.toLowerCase().includes(emailFilter.toLowerCase())
    );
    const toggleExpandAgent = (agentId) => {
      setExpandedAgentId(expandedAgentId === agentId ? null : agentId); // Changed from toggleExpandStore
    };
    
    const AgentData = async () => { // Changed from StoreData
      try {
        let response = await fetch(API_ENDPOINTS.AGENT, { // Changed endpoint
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`
          }
        });
        
        let data = await response.json();
        setAgents(data.agents); // Changed from setStores
      } catch (error) {
        notifyError("Failed to fetch data.");
      } 
    }

    useEffect(() => {
    AgentData();
    }, [authTokens]);
    return (
      <>
      <Notification />
      <div className="container-fluid px-2 px-md-4">
        <div className="page-header min-height-10 border-radius-xl mt-4">
          <h3>All Agents</h3>
        </div>
        <div className="card mb-4">
              <div className="card-header pb-0 px-3">
              <div className="row mb-3">
              <div className="col-md-4">
              <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Name"
                  value={nameFilter}
                  onChange={e => setNameFilter(e.target.value)}
              />
              </div>
              <div className="col-md-4">
              <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Username"
                  value={usernameFilter}
                  onChange={e => setUsernameFilter(e.target.value)}
              />
              </div>
              <div className="col-md-4">
              <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Email"
                  value={emailFilter}
                  onChange={e => setEmailFilter(e.target.value)}
              />
              </div>
              </div>
                <h6 className="mb-0">Agent Summary</h6>
              </div>
              <div className="card-body pt-4 p-3">
              <div className="table-responsive height_table_max">
              <table className="table align-items-center mb-0">
              <thead>
              <tr>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">ID</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Name</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Username</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Email</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Pay Rate</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Phone</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Morning Bonus</th>
                  <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10">Cash Collected</th>
              </tr>
              </thead>
              <tbody>
              {filteredAgents.map((agent) => (
                  <>
                  <tr key={agent.agent_id} onClick={() => toggleExpandAgent(agent.agent_id)} style={{cursor: 'pointer'}}>
                    <td>
                      <div className="d-flex px-0 py-1">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {agent.agent_id}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex px-0 py-1">
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {agent.agent_name}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>{agent.username}</td>
                    <td>{agent.agent_email}</td>
                    <td className="text-center">{`$${agent.pay_rate.toFixed(2)}`}</td>
                    <td>{agent.agent_phone}</td>
                    <td className="text-center">{`$${agent.morning_shift_bonus.toFixed(2)}`}</td>
                    <td className="text-center">{`$${agent.cash_collected_from_customer.toFixed(2)}`}</td>
                  </tr>
                  {expandedAgentId === agent.agent_id && (
                      <tr>
                          <td colSpan="12">
                              <div className="card card-body shadow-xxl m-3">
                                  <div className="row gx-4 mb-2">
                                      <div className="col-auto">
                                          <div className="avatar avatar-xl position-relative">
                                              <i className="material-icons text-primary w-100 border-radius-lg shadow-sm" style={{fontSize: '80px'}}>person</i>
                                          </div>
                                      </div>
                                      <div className="col-auto my-auto">
                                          <div className="h-100">
                                          <h5 className="mb-1">{agent.agent_name}</h5>
                                          <p className="mb-0 font-weight-normal text-sm">
                                                <h6 className="mb-1">{agent.agent_id}</h6>
                                                </p>
                                          <p className="mb-0 font-weight-normal text-sm">
                                              {agent.agent_phone}, {agent.agent_email}
                                            </p>
                                          <p className="mb-0 font-weight-normal text-sm">
                                            {agent.agent_address}
                                          </p>
                                          </div>
                                      </div>
                                      <div className="col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                          <div className="nav-wrapper position-relative end-0">
                                              <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                                  <li className="nav-item">
                                                      {editMode === agent.agent_id ? (
                                                          <div style={{ display: 'flex', gap: '10px' }}>
                                                              <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleSave}>
                                                                  <i className="material-icons text-lg position-relative">save</i>
                                                                  <span className="ms-1">Save</span>
                                                              </button>
                                                              <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleCancel}>
                                                                  <i className="material-icons text-lg position-relative">close</i>
                                                                  <span className="ms-1">Cancel</span>
                                                              </button>
                                                          </div>
                                                      ) : (
                                                          <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={() => handleEdit(agent)}>
                                                              <i className="material-icons text-lg position-relative">mode_edit</i>
                                                              <span className="ms-1">Edit</span>
                                                          </button>
                                                      )}
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-12 col-xl-4">
                                        <h6 className="mb-0">Agent Information</h6>
                                        <ul className="list-group p-1">
                                          <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Name:</strong> &nbsp;
                                            {editMode === agent.agent_id ? (
                                                <input
                                                    type="text"
                                                    value={editableAgent.agent_name}
                                                    onChange={(e) => handleChange('agent_name', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '200px' }}
                                                />
                                            ) : (
                                                agent.agent_name
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Username:</strong> &nbsp;
                                            {editMode === agent.agent_id ? (
                                                <input
                                                    type="text"
                                                    value={editableAgent.username}
                                                    onChange={(e) => handleChange('username', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '200px' }}
                                                />
                                            ) : (
                                                agent.username
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Email:</strong> &nbsp;
                                            {editMode === agent.agent_id ? (
                                                <input
                                                    type="text"
                                                    value={editableAgent.agent_email}
                                                    onChange={(e) => handleChange('agent_email', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '200px' }}
                                                />
                                            ) : (
                                                agent.agent_email
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Phone:</strong> &nbsp;
                                            {editMode === agent.agent_id ? (
                                                <input
                                                    type="text"
                                                    value={editableAgent.agent_phone}
                                                    onChange={(e) => handleChange('agent_phone', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '200px' }}
                                                />
                                            ) : (
                                                agent.agent_phone
                                            )}
                                        </li>
                                        </ul>
                                      </div>
                                      <div className="col-12 col-xl-4">
                                          <div className="card card-plain h-100">
                                              <div className="card-header pb-0 p-3">
                                                  <h6 className="mb-2"></h6>
                                              </div>
                                              <div className="card-body p-2">
                                              <div className="card-body p-1">
                                                  <ul className="list-group p-1">
                                                      
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Agent Type:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <select
                                                                  value={editableAgent.agent_type}
                                                                  onChange={(e) => handleChange('agent_type', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              >
                                                                  <option value="Freelancer">Freelancer</option>
                                                                  <option value="Captive">Captive</option>
                                                              </select>
                                                          ) : (
                                                              agent.agent_type
                                                          )}
                                                      </li>
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Address:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <input
                                                                  type="text"
                                                                  value={editableAgent.agent_address}
                                                                  onChange={(e) => handleChange('agent_address', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              />
                                                          ) : (
                                                              agent.agent_address
                                                          )}
                                                      </li>
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Joining Date:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <input
                                                                  type="text"
                                                                  value={editableAgent.joining_date}
                                                                  onChange={(e) => handleChange('joining_date', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              />
                                                          ) : (
                                                              agent.joining_date
                                                          )}
                                                      </li>
                                                  </ul>
                                              </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-12 col-xl-4">
                                          <div className="card card-plain h-100">
                                              <div className="card-header pb-0 p-3">
                                                  <h6 className="mb-2"></h6>
                                              </div>
                                              <div className="card-body p-3">
                                                  <ul className="list-group ">
                                                      
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Pay Rate:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <input
                                                                  type="text"
                                                                  value={editableAgent.pay_rate}
                                                                  onChange={(e) => handleChange('pay_rate', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              />
                                                          ) : (
                                                              `$${agent.pay_rate}`
                                                          )}
                                                      </li>
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Morning Bonus:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <input
                                                                  type="text"
                                                                  value={editableAgent.morning_shift_bonus}
                                                                  onChange={(e) => handleChange('morning_shift_bonus', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              />
                                                          ) : (
                                                              `$${agent.morning_shift_bonus}`
                                                          )}
                                                      </li>
                                                      <li className="list-group-item border-0 p-0 text-sm">
                                                          <strong className="text-dark">Cash Collected:</strong> &nbsp;
                                                          {editMode === agent.agent_id ? (
                                                              <input
                                                                  type="text"
                                                                  value={editableAgent.cash_collected_from_customer}
                                                                  onChange={(e) => handleChange('cash_collected_from_customer', e.target.value)}
                                                                  className='edit-input'
                                                                  style={{ width: '200px' }}
                                                              />
                                                          ) : (
                                                              `$${agent.cash_collected_from_customer}`
                                                          )}
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  )}
                  </>
              ))}
              </tbody>
          </table>
              </div>
              </div>
          </div>
        
      </div>
      </>
    )
  }

export default Agents